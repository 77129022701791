<template lang="pug">
  div
    el-alert(
      title="Bu bölümü görüntüleme yetkiniz yok"
      v-if="!can('taxi.list')"
      :closable="false"
      type="error"
      show-icon
      center
    )
    el-card(body-style="padding:0")
      div(slot="header")
        el-form(inline)
          el-form-item
            el-button(
              type="primary"
              @click="create"
              icon="el-icon-plus"
              :disabled="!can('taxi.list')"
            ) Oluştur
      el-input(placeholder="Taksi ara" v-model="search" v-if="can('taxi.list')")
        i.el-input__icon.el-icon-search(slot="prefix")
      el-table(
        :data="filteredTaxis"
        style="width: 100%"
        v-loading="fetching"
        @row-dblclick="edit"
        size="mini"
        :height="$store.state.windowHeight-184"
        v-if="can('taxi.list')"
      )
        el-table-column(type="index" width="34px" class-name="text-muted text-size-mini" align="right")
        el-table-column(prop="name" label="Taksi" sortable width="160px")
        el-table-column(prop="phone" label="Telefon" sortable width="120px")
        el-table-column(prop="email" label="Email" sortable width="120px")
        el-table-column(prop="address" label="Adres" sortable)
        el-table-column(prop="margin" label="Opr. katkı payı" sortable width="120px" align="right")
        el-table-column(label="KOMİSYON ORANLARI" align="center" class-name="text-primary")
          el-table-column(class-name="text-primary" align="right" width="125" v-for="c in categories" :key="c.id" prop="commissions" :label="c.name")
            template(v-slot="props") {{ getCommission(props.row.id, c.id) }}
        el-table-column(prop="id" label="İşlem" align="center")
          template(v-slot="props")
            el-button-group
              el-button.micro(plain @click="edit(props.row)"): span.el-icon-edit
              el-button.micro(plain @click="remove(props.row.id)"): span.el-icon-delete.text-danger
    el-drawer(:visible.sync="modal" size="600px")
      span.text-center(slot="title") {{ taxi.name }}
      el-form.pt-20.pr-20.pl-20(label-position="right" label-width="130px")
        el-form-item(label="Taksi")
          el-input(v-model="taxi.name")
        el-form-item(label="Telefon")
          el-input(v-model="taxi.phone")
        el-form-item(label="E-mail")
          el-input(v-model="taxi.email")
        el-form-item(label="Adres")
          el-input(v-model="taxi.address")
        el-form-item(label="Opr. katkı payı")
          el-input(v-model.number="taxi.margin")
        el-form-item(label="Devir")
          el-input(type="number" v-model.number="taxi.account_transfer")
      el-divider: span.text-primary Komisyon oranları, %
      entity-commissions(v-model="taxi.commissions" :key="`taxi-${taxi.id}`")
      center.pt-20: el-button(@click="save" :loading="saving" icon="el-icon-check") Kaydet
</template>
<script>
import get from 'lodash/get'
import ProductCategory from '@/models/ProductCategory'
import Company from '@/models/Company'
import EntityCommissions from '@/components/EntityCommissions.vue'
export default {
  name: 'taxis',
  components: { EntityCommissions },
  data(){
    return {
      fetching: false,
      saving: false,
      modal: false,
      search: '',
      taxi: new Company({ name: '', type: 'taxi', phone: '', email: '', address: '', margin: 0, account_transfer: 0, commissions: [] }),
      taxis: [],
      categories: []
    }
  },
  async created(){
    if(this.can('taxi.list')) {
      this.categories = await ProductCategory.get()
      this.fetch()
    }
  },
  methods: {
    async fetch(){
      try{
        this.fetching = true
        this.taxis = await Company.include('commissions').where('type', 'taxi').get()
        this.fetching = false
      }catch({ response }){
        this.$message.error(response.data.message)
        this.fetching = false
      }
    },
    create(){
      this.taxi = new Company({ name: '', type: 'taxi', phone: '', email: '', address: '', margin: 0, account_transfer: 0, commissions: [] })
      this.modal = true
    },
    async edit({ id }){
      this.taxi = await Company.include('commissions').find(id)
      this.modal = true
    },
    async save(){
      try{
        this.saving = true
        await this.taxi.save()
        this.saving = false
        this.modal = false
        this.fetch()
      }catch(e){
        this.saving = false
        this.$message.error(e.message)
      }
    },
    async remove(id){
      await this.$confirm('Kayıt silinmesine onay veriniz', 'Uyarı', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Vazgeç',
        type: 'warning',
        center: true
      })
      try{
        let { data } = await window.axios.delete('/companies/' + id)
        this.fetch(false)
        if(data.deleted){
          this.$message({
            type: 'success',
            message: 'Kayıt silinmiştir',
            center: true,
            closable: true
          })
        }
      }catch(e){
        this.$message({
          type: 'error',
          message: 'Üzerine satış, rezervasyon veya ödeme tanımlanmıştır. Kayıt silinemez',
          center: true,
          closable: false
        })
      }
    },
    getCommission(id, category_id){
      let model = this.taxis.find(a => a.id === id)
      let comm = model.commissions.find(c => c.category_id === category_id)
      return get(comm, 'foreign', 0)
    }
  },
  computed: {
    filteredTaxis: function(){
      return this.taxis.filter(a => a.name.toLowerCase().includes(this.search.toLowerCase()))
    }
  }
}
</script>
